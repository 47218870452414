import React from 'react';
import {Box, Button} from '@material-ui/core';
import {useAppContext, UserOnly} from '../providers/AppProvider';
import {useHistory} from 'react-router-dom';
import TuneIcon from '@material-ui/icons/Tune';
import RouterIcon from '@material-ui/icons/Router';
import SettingsRemoteIcon from '@material-ui/icons/SettingsRemote';
import ListIcon from '@material-ui/icons/ListAlt';
import {superAdmins} from '../common/slot-types';

const BUTTON_WIDTH = 300;

const Home: React.FC = () => {
  const {user} = useAppContext();
  const history = useHistory();
  const onSettingsClick = () => {
    history.push('/settings/prob');
  };
  const onStatusClick = () => {
    history.push('/status');
  };
  const onCallClick = () => {
    history.push('/call');
  };
  const onLogViewClick = () => {
    history.push('/logs');
  };
  const isSuperAdmin = superAdmins.includes(user?.uid ?? '');
  return (
    <UserOnly fallbackPath={'/account/sign-in'} display={'flex'} flexDirection={'column'} alignItems={'center'} minHeight={'calc(100vh - 48px)'}>
      <Box mt={4} width={BUTTON_WIDTH}>
        <Button variant={'contained'} fullWidth size={'large'} startIcon={<RouterIcon />} onClick={onStatusClick}>실시간 게임 현황</Button>
      </Box>
      <Box mt={2} width={BUTTON_WIDTH}>
        <Button variant={'contained'} fullWidth size={'large'} startIcon={<SettingsRemoteIcon />} onClick={onCallClick}>콜설정 / 원격설정</Button>
      </Box>
      {isSuperAdmin && (
        <Box mt={2} width={BUTTON_WIDTH}>
          <Button variant={'contained'} fullWidth size={'large'} startIcon={<TuneIcon />} onClick={onSettingsClick}>게임 / 확률 설정</Button>
        </Box>
      )}
      {isSuperAdmin && (
        <Box mt={2} width={BUTTON_WIDTH}>
          <Button variant={'contained'} fullWidth size={'large'} startIcon={<ListIcon />} onClick={onLogViewClick}>기기별 게임 로그</Button>
        </Box>
      )}
    </UserOnly>
  );
};

export default Home;
