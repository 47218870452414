import React from 'react';
import {Box} from '@material-ui/core';
import {AgGridReact} from 'ag-grid-react';
import useLogsGrid from '../hooks/useLogsGrid';
import {Moment} from 'moment';

export interface LogsGridProps {
  storeId?: string;
  deviceId?: string;
  filterDate?: Moment | null;
}

const LogsGrid: React.FC<LogsGridProps> = (props) => {
  const {boxProps, gridProps} = useLogsGrid(props.storeId, props.deviceId, props.filterDate);
  return (
    <Box {...boxProps}>
      <AgGridReact {...gridProps}/>
    </Box>
  );
};

export default LogsGrid;
