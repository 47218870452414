import React from 'react';
import {Box, BoxProps} from '@material-ui/core';
import {GridApi} from 'ag-grid-community';
import {AgGridReact, AgGridReactProps} from 'ag-grid-react';
import clsx from 'clsx';

export interface UseAgGridParam extends AgGridReactProps {
  boxProps?: BoxProps;
}

export interface AgGridProps {
  boxProps: BoxProps;
  gridProps: AgGridReactProps;
}

export interface UseAgGridValue extends AgGridProps {
  gridRef: React.MutableRefObject<GridApi | undefined>;
}

export type UseAgGrid = (param: UseAgGridParam) => UseAgGridValue;

export const useAgGrid: UseAgGrid = (param) => {
  const gridRef = React.useRef<GridApi>();
  return React.useMemo(() => {
    const {
      boxProps: {
        className,
        width = '100%',
        ...boxProps
      } = {},
      onGridReady,
      ...gridProps
    } = param;
    return {
      boxProps: {
        className: clsx('ag-theme-balham-dark', className),
        width,
        ...boxProps,
      },
      gridProps: {
        ...gridProps,
        onGridReady(e) {
          gridRef.current = e.api;
          onGridReady?.(e);
        }
      },
      gridRef,
    };
  }, [param]);
};

export const AgGrid: React.FC<AgGridProps> = (props) => {
  const {boxProps, gridProps} = props;
  return (
    <Box {...boxProps} >
      <AgGridReact {...gridProps} />
    </Box>
  );
};
