import React from 'react';
import {MenuItem, TextField, TextFieldProps} from '@material-ui/core';
import {SelectOption} from '../common/types';

export interface SelectFieldProps {
  textFieldProps: TextFieldProps;
  options?: SelectOption[]
}

const SelectField: React.FC<SelectFieldProps> = ({textFieldProps, options}) => {
  return (
    <TextField {...textFieldProps}>
      {options?.map(({value, label}, index) => <MenuItem key={`${value}-${index}`} value={value}>{label}</MenuItem>)}
    </TextField>
  );
};

export default SelectField;
