import React from 'react';
import {UserOnly} from '../providers/AppProvider';
import {Box, Grid, makeStyles, Typography} from '@material-ui/core';
import {DatePicker} from '@material-ui/pickers';
import {DateTextField, StandaloneInputRef, StandaloneSelect} from '../components/StandaloneInput';
import LogsGrid from '../components/LogsGrid';
import moment, {Moment} from 'moment';
import slotFire from '../common/slot-fire';

const Logs: React.FC = () => {
  const [storeId, setStoreId] = React.useState<string>();
  const [deviceId, setDeviceId] = React.useState<string>();
  const [logDate, setLogDate] = React.useState<Moment | null>(moment());
  const [storeOptions, deviceOptions] = slotFire.useStoreOptions(true);
  const gridClasses = useGridStyles();
  const storeRef = React.useRef<StandaloneInputRef>(null);
  const deviceRef = React.useRef<StandaloneInputRef>(null);
  React.useEffect(() => {
    if (storeOptions?.length > 0 && !storeId) {
      const newStoreId = storeOptions[0].value;
      storeRef.current?.set(newStoreId);
    }
  }, [storeOptions, storeId]);
  React.useEffect(() => {
    if (storeId) {
      const newDeviceId = deviceOptions[storeId]?.[0].value;
      deviceRef.current?.set(newDeviceId);
    }
  }, [storeId, deviceOptions]);
  return (
    <UserOnly superAdminOnly fallbackPath={'/account/sign-in'} padding={2} minHeight={'calc(100vh - 48px)'}>
      <Grid classes={gridClasses} container spacing={2}>
        <Grid item xs={2}>
          <Box mt={2}>
            <StandaloneSelect ref={storeRef} name={'storeId'} label={'매장'}  options={storeOptions} onChange={(name, value) => setStoreId(value)} />
          </Box>
          {/*<Box mt={2}>*/}
          {/*  <StandaloneSelect ref={deviceRef} name={'deviceId'} label={'기기'} options={storeId ? deviceOptions[storeId] : []} onChange={(name, value) => setDeviceId(value)} />*/}
          {/*</Box>*/}
          <Box mt={2}>
            <DatePicker variant={'inline'} label={'날짜'} autoOk={true} value={logDate} onChange={(date) => setLogDate(date)} format={'YYYY-MM-DD'} TextFieldComponent={DateTextField} />
          </Box>
        </Grid>
        <Grid item xs={10}>
          <Typography variant={'h6'}>기기별 게임 로그</Typography>
          {(storeId) && <LogsGrid storeId={storeId} deviceId={deviceId} filterDate={logDate} />}
        </Grid>
      </Grid>
    </UserOnly>
  );
};

const useGridStyles = makeStyles(() => {
  return {
    root: {
      height: '100%',
    }
  };
});

export default Logs;
