import React from 'react';
import {DataGridParams, useSimpleDataGrid} from './useDataGrid';
import {Box} from '@material-ui/core';
import {ColDef, GridApi} from 'ag-grid-community';
import firebase from 'firebase/app';
import moment from 'moment';
import {ReelCountType, reelTypeLabel} from '../common/slot-types';
import 'moment/locale/ko';
import {useHistory} from 'react-router-dom';
import slotFire from '../common/slot-fire';
import fire from '../common/fire';  // without this line it didn't work

moment.locale('ko');

export default function useStatusGrid(storeId: string) {
  const devices = slotFire.useDevices(storeId);
  const gridRef = React.useRef<GridApi>();
  const history = useHistory();
  const params = React.useMemo(() => {
    const gridParams: DataGridParams = {
      containerProps: {
        width: '100%',
        height: 'calc(100vh - 48px - 32px - 32px)',
      },
      columnDefs: (() => {
        if (fire.uid === 'yeebwn@gmail.com' || fire.uid === 'song@nosong.com') {
          return [
            {field: 'deviceId', hide: true},
            {field: 'deviceName', headerName: '디바이스', width: 100},
            {field: 'status', headerName: '상태', valueFormatter: formatStatus, width: 100},
            {field: 'reelCount', headerName: '릴종류', valueFormatter: formatReelCount, width: 100},
            {field: 'probIndex', headerName: '확률순번', width: 100},
            {field: 'holdCount', headerName: '헛바퀴', width: 100},
            {field: 'eventActive', headerName: '현재이벤트', valueFormatter: formatEvent, width: 100},
            {field: 'nextEventIndex', headerName: '다음이벤트', valueFormatter: formatNextEvent, width: 100},
            {field: 'credit', headerName: '크레딧', width: 100},
            {field: 'win', headerName: '현재점수', width: 100},
            {field: 'winTotal', headerName: '누적점수', width: 100},
            {field: 'jackpot', headerName: '현재잭팟', width: 100},
            {field: 'jackpotGoal', headerName: '잭팟설정', width: 100},
            {field: 'updatedAt', headerName: '업데이트', valueFormatter: formatUpdatedAt, width: 100},
            {field: 'betAmount', headerName: '베팅금액', width: 100},
            {field: 'betTotal', headerName: '누적베팅', width: 100},
            {field: 'winPlay', headerName: '윈플레이', width: 100},
            {field: 'winPlayCount', headerName: '윈카운트', width: 100},
          ];
        } else {
          return [
            {field: 'deviceId', hide: true},
            {field: 'deviceName', headerName: '디바이스', width: 100},
            {field: 'status', headerName: '상태', valueFormatter: formatStatus, width: 100},
            {field: 'reelCount', headerName: '릴종류', valueFormatter: formatReelCount, width: 100},
            {field: 'probIndex', headerName: '확률순번', width: 100},
            {field: 'holdCount', headerName: '헛바퀴', width: 100},
            {field: 'eventActive', headerName: '현재이벤트', valueFormatter: formatEvent, width: 100},
            {field: 'nextEventIndex', headerName: '다음이벤트', valueFormatter: formatNextEvent, width: 100},
            {field: 'credit', headerName: '크레딧', width: 100},
            {field: 'win', headerName: '현재점수', width: 100},
            {field: 'winTotal', headerName: '누적점수', width: 100},
            {field: 'jackpot', headerName: '현재잭팟', width: 100},
            {field: 'jackpotGoal', headerName: '잭팟설정', width: 100},
            {field: 'updatedAt', headerName: '업데이트', valueFormatter: formatUpdatedAt, width: 100},
          ];
        }
      })(),
      defaultColDef: {resizable: true},
      onGridReady(e) {
        gridRef.current = e.api;
        // gridRef.current?.setRowData(devices ?? []);
      },
      onCellDoubleClicked(e) {
        const {deviceId} = e.data;
        history.push('/call', {storeId, deviceId});
      },
      loadingOverlayComponentFramework: () => <Box color={'rgba(255,255,255,.5)'}>매장을 선택하세요!</Box>,
      deltaRowDataMode: true,
      getRowNodeId: function (data) {
        return data.deviceId;
      },
    };
    return gridParams;
  }, [storeId, history]);
  React.useEffect(() => {
    gridRef.current?.setRowData(devices ?? []);
  }, [devices]);
  return useSimpleDataGrid(params);
}

const formatStatus: ColDef['valueFormatter'] = (p) => {
  switch (p.value) {
    case 'created':
      return '🚀 생성됨';
    case 'off':
      return '⛔ 오프라인';
    case 'init':
      return '⚪ 초기화됨';
    case 'idle':
      return '💤 대기중';
    case 'spin':
      return '🌀 스핀중';
    case 'reward':
      return '🟢 보상중';
    default:
      return '';
  }
};

const formatReelCount: ColDef['valueFormatter'] = (p) => {
  return reelTypeLabel[p.value as ReelCountType];
};

const formatEvent: ColDef['valueFormatter'] = (p) => {
  return p.value ? p.data['eventInfo'] : '없음'
};

const formatNextEvent: ColDef['valueFormatter'] = (p) => {
  return p.value ? `${p.value}, ${p.data['nextEventInfo']}` : '없음';
};

const formatUpdatedAt: ColDef['valueFormatter'] = (p) => {
  if (typeof p.value === 'number') {
    return moment(p.value * 1000).fromNow();
  }
  if (p.value instanceof firebase.firestore.Timestamp) {
    const updateAt = p.value.toDate();
    return moment(updateAt).fromNow();
  }
  return '';
};
