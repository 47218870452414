import React from 'react';

export type SharedDialogType = 'alert' | 'confirm';

export interface SharedDialogProps {
  type: SharedDialogType;
  open: boolean;
  title: string;
  message: string;
  onClose?: (ok?: boolean) => void;
  okLabel?: string;
  cancelLabel?: string;
}

interface SharedDialogState {
  type: SharedDialogType;
  open: boolean;
  title: string;
  message: string;
  onClose?: (ok?: boolean) => void;
  okLabel?: string;
  cancelLabel?: string;
}

export type ShareDialogOpen = (
  type: SharedDialogType,
  title: string,
  message: string,
  onClose?: (ok?: boolean) => void,
  okLabel?: string,
  cancelLabel?: string
) => void;

type UseSharedDialog = [ShareDialogOpen, SharedDialogProps];

const initialDialogState: SharedDialogState = {
  type: 'alert',
  open: false,
  title: '',
  message: '',
};

export function useSharedDialog(): UseSharedDialog {
  const [state, setState] = React.useState<SharedDialogState>();
  return React.useMemo(() => {
    const open: ShareDialogOpen = (type, title, message, onClose, okLabel, cancelLabel) => {
      setState({type, open: true, title, message, onClose, okLabel, cancelLabel});
    };
    const props: SharedDialogProps = {
      ...(state ?? initialDialogState),
      onClose: (ok) => {
        state?.onClose?.(ok);
        setState(initialDialogState);
      }
    };
    return [open, props];
  }, [state]);
}
