import React from 'react';
import {DataGridParams, useSimpleDataGrid} from './useDataGrid';
import {Box} from '@material-ui/core';
import {ColDef, GridApi} from 'ag-grid-community';
import firebase from 'firebase/app';
import moment, {Moment} from 'moment';
import 'moment/locale/ko'
import slotFire from '../common/slot-fire';  // without this line it didn't work
moment.locale('ko');

export default function useLogsGrid(storeId?: string, deviceId?: string, filterDate?: Moment | null) {
  const logList = slotFire.useLogs(storeId, deviceId, filterDate || moment());
  const gridRef = React.useRef<GridApi>();
  const params = React.useMemo(() => {
    const gridParams: DataGridParams = {
      containerProps: {
        width: '100%',
        height: 'calc(100vh - 48px - 32px - 32px)',
      },
      columnDefs: [
        {field: 'deviceId', headerName: '디바이스', valueFormatter: formatDevice, width: 150},
        {field: 'createdAt', headerName: '시간', valueFormatter: formatCreatedAt, width: 150},
        {field: 'log', headerName: '내용', valueFormatter: formatLog, flex: 1},
      ],
      defaultColDef: {resizable: true},
      onGridReady(e) {
        gridRef.current = e.api;
        gridRef.current?.setRowData(logList ?? []);
      },
      loadingOverlayComponentFramework: () => <Box color={'rgba(255,255,255,.5)'}>매장을 선택하세요!</Box>,
    };
    return gridParams;
  }, [logList]);
  React.useEffect(() => {
    gridRef.current?.setRowData(logList ?? []);
  }, [logList]);
  return useSimpleDataGrid(params);
}

const formatDevice: ColDef['valueFormatter'] = (p) => {
  if (p.value) {
    return p.value?.split('@')?.[0];
  }
  return '';
};

const formatCreatedAt: ColDef['valueFormatter'] = (p) => {
  if (p.value) {
    const updateAt = (p.value as firebase.firestore.Timestamp).toDate();
    return moment(updateAt).format('YYYY-MM-DD kk:mm:ss');
  }
  return '';
};

const formatLog: ColDef['valueFormatter'] = (p) => {
  return p.value?.replaceAll('lily', 'local').replaceAll('robin', 'remote').replaceAll('Lily', 'Local').replaceAll('Robin', 'Remote');
};
