import React from 'react';
import slotFire from '../../common/slot-fire';
import {betAmounts, DEF_MAX_INDEX, ReelCountType, RemoteSetting, winPlayCounts} from '../../common/slot-types';
import {initialFakeData} from '../ag-grid/useFakeAgGrid';
import {getInitialProbData} from '../ag-grid/useProbAgGrid';
import useStoreSelectField from './useStoreSelectField';
import useReelCountSelectField from './useReelCountSelectField';
import {SelectFieldProps} from '../../components/SelectField';

export interface UseStoreSettingFormValue {
  storeId: string | undefined;
  reelCount: ReelCountType | undefined;
  setting: RemoteSetting | null | undefined;
  betAmount: number | undefined;
  totalBet: number | undefined;
  storeSelectProps: SelectFieldProps;
  reelCountSelectProps: SelectFieldProps;
}

const useStoreSettingForm: () => UseStoreSettingFormValue = () => {
  const {value: storeId, textFieldProps: storeFieldProps, options: storeOptions} = useStoreSelectField();
  const {value: reelCountVal, textFieldProps: reelCountFieldProps, options: reelCountOptions} = useReelCountSelectField(storeId);
  const reelCount = reelCountVal as ReelCountType | undefined;
  const setting = slotFire.useSetting(storeId, reelCount);
  const betAmount = reelCount ? betAmounts[reelCount] : undefined;
  const maxIndex = setting?.maxIndex;
  const totalBet = betAmount && maxIndex ? betAmount * maxIndex : undefined;
  const remoteStore = slotFire.useStore(storeId);
  React.useEffect(() => {
    if (storeId && reelCount && remoteStore) {
      if (setting === undefined) {
        console.log('서버 설정이 존재하지 않으므로 초기화함', setting);
        slotFire.setSetting(storeId, reelCount, {
          maxIndex: DEF_MAX_INDEX,
          betAmount: betAmounts[reelCount],
          winPlayCount: winPlayCounts[reelCount],
          ...initialFakeData,
          ...getInitialProbData(reelCount, remoteStore.smallWin ?? false)
        }).catch(console.error);
      }
    }
  }, [storeId, reelCount, setting, remoteStore]);
  return {
    storeId, reelCount, setting, betAmount, totalBet,
    storeSelectProps: {textFieldProps: storeFieldProps, options: storeOptions},
    reelCountSelectProps: {textFieldProps: reelCountFieldProps, options: reelCountOptions},
  };
};

export default useStoreSettingForm;
