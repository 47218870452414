import React from 'react';
import useSelectField, {UseSelectFieldParams, UseSelectFieldValue} from './useSelectField';
import {DEF_REEL_COUNT, reelCountOptions} from '../../common/slot-types';

export default function useReelCountSelectField(storeId: string | undefined): UseSelectFieldValue {
  const selectParams: UseSelectFieldParams = React.useMemo(() => {
    return {options: reelCountOptions, name: 'reelCount', label: '릴 타입 (베팅금액)'};
  }, []);
  const res = useSelectField(selectParams);
  const {setValue} = res;
  React.useEffect(() => {
    if (storeId) {
      setValue(DEF_REEL_COUNT as string);
    } else {
      setValue(undefined);
    }
  }, [storeId, setValue]);
  return res;
}
