import React from 'react';
import {TextFieldProps} from '@material-ui/core';
import {SelectOption} from '../../common/types';
import {REQUIRED_FIELD_MESSAGE} from './useTextField';

export interface UseSelectFieldParams extends Omit<TextFieldProps, 'value' | 'defaultValue' | 'select'> {
  initialValue?: string;
  options: SelectOption[] | undefined;
  validateOnMount?: boolean;
  validateOnFocus?: boolean;
  validateOnBlur?: boolean;
  validateOnChange?: boolean;
}

export interface UseSelectFieldValue {
  value: string | undefined;
  error: Error | undefined;
  setValue: React.Dispatch<React.SetStateAction<string | undefined>>;
  setDisabled: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  textFieldProps: TextFieldProps;
  options: SelectOption[] | undefined;
}

export default function useSelectField(params: UseSelectFieldParams): UseSelectFieldValue {
  const {initialValue, disabled: disabledParam, required, options, validateOnMount} = params;
  const [value, setValue] = React.useState<string | undefined>(initialValue);
  const [error, setError] = React.useState<Error | undefined>();
  const [disabled, setDisabled] = React.useState<boolean | undefined>(disabledParam);
  React.useEffect(() => {
    if (validateOnMount) {
      setError(validateValue(value, required));
    }
  }, [required, validateOnMount, value]);
  return React.useMemo<UseSelectFieldValue>(() => {
    const {
      required, onChange, onFocus, onBlur,
      validateOnBlur, validateOnChange, validateOnFocus,
      ...others
    } = params;
    const textFieldProps: TextFieldProps = {
      ...defaultTextFieldProps,
      ...others,
      disabled,
      onChange: (e) => {
        const {target: {value: newValue}} = e;
        setValue(newValue);
        if (validateOnChange) {
          setError(validateValue(newValue, required));
        }
        onChange?.(e);
      },
      onFocus: (e) => {
        if (validateOnFocus) {
          setError(validateValue(value, required));
        }
        onFocus?.(e);
      },
      onBlur: (e) => {
        if (validateOnBlur) {
          setError(validateValue(value, required));
        }
        onBlur?.(e);
      },
      error: Boolean(error),
      helperText: error?.message,
      value: value ?? '',
    };
    return {
      value, setValue,
      error,
      setDisabled,
      textFieldProps,
      options,
    };
  }, [disabled, error, options, params, value]);
}

function validateValue(value?: string, required?: boolean): Error | undefined {
  if (required && !value) {
    return new Error(REQUIRED_FIELD_MESSAGE);
  }
  return undefined;
}

const defaultTextFieldProps: TextFieldProps = {
  fullWidth: true,
  size: 'small',
  select: true,
};
