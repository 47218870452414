import React from 'react';
import {GuestOnly} from '../../providers/AppProvider';
import {Box, Button, Card, CardActions, CardContent, makeStyles, Typography} from '@material-ui/core';
import {StandaloneInput, StandaloneInputRef} from '../../components/StandaloneInput';
import fire from '../../common/fire';

const SignIn: React.FC = () => {
  const cardClasses = useCardStyles();
  const cardActionClasses = useCardActionStyles();
  const emailRef = React.useRef<StandaloneInputRef>(null);
  const passwordRef = React.useRef<StandaloneInputRef>(null);
  return (
    <GuestOnly fallbackPath={'/'} minHeight={'calc(100vh - 48px)'} display={'flex'} alignItems={'flex-start'} justifyContent={'center'}>
      <Card classes={cardClasses}>
        <CardContent>
          <Typography variant={'h5'} gutterBottom>관리자 로그인</Typography>
          <Box mt={2}>
            <StandaloneInput ref={emailRef} name={'email'} label={'이메일'} type={'email'} />
          </Box>
          <Box mt={2}>
            <StandaloneInput ref={passwordRef} name={'password'} label={'패스워드'} type={'password'} />
          </Box>
        </CardContent>
        <CardActions classes={cardActionClasses}>
          <Button
            variant={'contained'}
            onClick={async () => {
              const email = emailRef.current?.value;
              const password = passwordRef.current?.value;
              if (email && password) {
                try {
                  await fire.signIn(email, password);
                } catch (error) {
                  console.log(error);
                }
              }
            }}
          >
            로그인
          </Button>
        </CardActions>
      </Card>
    </GuestOnly>
  );
};

const useCardStyles = makeStyles(() => {
  return {
    root: {
      width: 500,
      marginTop: 32,
    }
  };
});

const useCardActionStyles = makeStyles(() => {
  return {
    root: {
      justifyContent: 'center',
      marginBottom: 16,
    }
  };
});

export default SignIn;
