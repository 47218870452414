import React from 'react';
import {Box, BoxProps} from '@material-ui/core';
import {AgGridEvent, CellEvent, ColumnApi, ColumnEvent, GridApi, RowEvent} from 'ag-grid-community';
import {AgGridReact, AgGridReactProps} from 'ag-grid-react';
import clsx from 'clsx';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham-dark.css';

export interface DataGridParams extends AgGridReactProps {
  containerProps: BoxProps;
}

type OnGridReady = AgGridReactProps['onGridReady'];

export default function useDataGrid(params: DataGridParams) {
  const [rows, setRows] = React.useState<any[]>();
  const gridApiRef = React.useRef<GridApi>();
  const columnApiRef = React.useRef<ColumnApi>();
  return React.useMemo(() => {
    const {
      containerProps: {
        className: containerClassName,
        ...containerBoxProps
      },
      onGridReady: onGridReadyInternal,
      ...gridProps
    } = params;
    const onGridReady: OnGridReady = (e) => {
      gridApiRef.current = e.api;
      columnApiRef.current = e.columnApi;
      onGridReadyInternal?.(e);
    };
    const boxProps = {
      className: clsx('ag-theme-balham-dark', containerClassName),
      ...containerBoxProps,
    };
    const DataGrid: React.FC = () => {
      return (
        <Box {...boxProps}>
          <AgGridReact {...gridProps} onGridReady={onGridReady} />
        </Box>
      );
    };
    return {rows, setRows, gridApiRef, columnApiRef, DataGrid};
  }, [params, rows]);
}

export function useSimpleDataGrid(params: DataGridParams) {
  const {
    containerProps: {
      className: containerClassName,
      ...containerBoxProps
    },
    ...gridProps
  } = params;
  const boxProps = {
    className: clsx('ag-theme-balham-dark', containerClassName),
    ...containerBoxProps,
  };
  return {boxProps, gridProps};
}

export class AgGridUtil {
  constructor(private event: AgGridEvent | RowEvent | ColumnEvent | CellEvent) {}
  getRows() {
    const rows: any[] = [];
    this.event.api.forEachNode((rowNode) => rows.push(rowNode.data));
    return rows;
  }
  setRows(rows: any[]) {
    this.event.api.setRowData(rows);
  }
  updateRow(data: any) {
    const e = this.event as RowEvent;
    e.node.setData(data);
  }
  updateCell(colKey: string, value: any) {
    const e = this.event as RowEvent;
    e.node.setDataValue(colKey, value);
  }
}
