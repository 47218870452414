import React from 'react';
import useSelectField, {UseSelectFieldParams, UseSelectFieldValue} from './useSelectField';
import slotFire from '../../common/slot-fire';
import {SelectOption} from '../../common/types';

export interface UseStoreSelectField extends UseSelectFieldValue {
  devices:  Record<string, SelectOption[]>;
}

export default function useStoreSelectField(includeDevices?: boolean): UseStoreSelectField {
  const [options, devices] = slotFire.useStoreOptions(includeDevices);
  const selectParams: UseSelectFieldParams = React.useMemo(() => {
    return {options: options, name: 'storeId', label: '매장 선택'};
  }, [options]);
  const res = useSelectField(selectParams);
  const {setValue} = res;
  React.useEffect(() => {
    if (options?.length > 0) {
      setValue(options[0].value);
    }
  }, [options, setValue]);
  return {...res, devices};
}
