import React from 'react';
import {Box, Button, colors, Grid, makeStyles, Typography} from '@material-ui/core';
import {UserOnly} from '../../providers/AppProvider';
import useFakeAgGrid, {initialFakeData} from '../../hooks/ag-grid/useFakeAgGrid';
import {AgGrid} from '../../hooks/ag-grid/useAgGrid';
import useStoreSettingForm from '../../hooks/form/useStoreSettingForm';
import slotFire from '../../common/slot-fire';
import useProbAgGrid, {getInitialProbData} from '../../hooks/ag-grid/useProbAgGrid';
import MaxIndexField from '../../components/MaxIndexField';
import SelectField from '../../components/SelectField';
import WinPlayField from '../../components/WinPlayField';

const ProbV2: React.FC = () => {
  const gridClasses = useGridStyles();
  const {
    storeId, reelCount, setting, totalBet,
    storeSelectProps, reelCountSelectProps,
  } = useStoreSettingForm();
  const fakeGridProps = useFakeAgGrid(storeId, reelCount, setting);
  const [probGridProps, [totalReward, totalAppear]] = useProbAgGrid(storeId, reelCount, setting);
  const remoteStore = slotFire.useStore(storeId);
  return (
    <UserOnly superAdminOnly fallbackPath={'/'} padding={2} minHeight={'calc(100vh - 48px)'}>
      <Grid classes={gridClasses} container spacing={2}>
        <Grid item xs={2}>
          <Typography variant={'h6'}>게임 설정</Typography>
          <Box mt={2}>
            <SelectField {...storeSelectProps} />
          </Box>
          <Box mt={2}>
            <SelectField {...reelCountSelectProps} />
          </Box>
          <Box mt={2}>
            <MaxIndexField storeId={storeId} reelCount={reelCount} setting={setting} />
          </Box>
          <Box mt={2}>
            <WinPlayField storeId={storeId} reelCount={reelCount} setting={setting} />
          </Box>
          <Box mt={2} display={'flex'} flexDirection={'column'}>
            <Typography variant={'overline'} color={colors.grey[500]}>총투입금액 (원)</Typography>
            <Typography variant={'body1'}>{formatCurrency(totalBet)}</Typography>
            <Typography variant={'overline'} color={colors.grey[500]}>총보상금액 (원)</Typography>
            <Typography variant={'body1'}>{formatCurrency(totalReward)} {formatRate(totalReward, totalBet)}</Typography>
            <Typography variant={'overline'} color={colors.grey[500]}>총보상횟수</Typography>
            <Typography variant={'body1'}>{totalAppear ? totalAppear : '--'}</Typography>
          </Box>
          <Box mt={2}>
            <Button variant={'contained'} fullWidth onClick={async () => {
              if (storeId && reelCount) {
                const probData = getInitialProbData(reelCount, remoteStore?.smallWin ?? false);
                await slotFire.setSetting(storeId, reelCount, {...probData, ...initialFakeData});
              }
            }}>확률 초기화</Button>
          </Box>
        </Grid>
        <Grid item xs={10}>
          <Typography variant={'h6'}>확률 설정</Typography>
          <AgGrid {...probGridProps} />
          <Box mt={2}>
            <Typography variant={'h6'}>뻥 데이터 설정</Typography>
          </Box>
          <AgGrid {...fakeGridProps} />
        </Grid>
      </Grid>
    </UserOnly>
  );
};

const useGridStyles = makeStyles(() => {
  return {
    root: {
      height: '100%',
    }
  };
});

function formatCurrency(amount: any) {
  if (amount > 0) {
    return amount >= 10000 ? `${amount / 10000}만` : amount;
  }
  return '--';
}

function formatRate(son: any, mom: any) {
  if (son && mom) {
    return `(${(son / mom * 100).toFixed(2)} %)`;
  }
  return '';
}

export default ProbV2;
