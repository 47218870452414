import React from 'react';
import {CellValueChangedEvent, ColDef} from 'ag-grid-community';
import {getSuddenLabel, ReelCountType, SlotFakeHintType, RemoteSetting, FakeInfo} from '../../common/slot-types';
import {AgGridProps, useAgGrid, UseAgGridParam} from './useAgGrid';
import slotFire from '../../common/slot-fire';

/**
 * ## *꽝 데이터 그리드 훅*
 * - 🔍 서버에 저장된 꽝 데이터를 읽어와서 그리드에 표시해줌
 * - 💾 꽝 데이터 변경시 실시간으로 서버에 저장함
 * - AgGrid 컴포넌트와 짝을 이루어 사용함
 * - 해파리(상), 버블(상), 가오리(상), 상어(상), 고래(상), 황금고래(상), 복어(하), 거북이(하), 돌고래(하), 상어(하), 고래(하) 순으로 꽝 데이터의 갯수가 저장됨
 *
 * ## *Server Data*
 * ```javascript
 * const setting = {
 *   fake0: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], // 단계 데이터
 *   fake1: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], // 돌발 데이터
 *   // other settings ...
 * };
 * ```
 * ## *Grid Data*
 * ```javascript
 * const rowData = {
 *   sudden: true, // true: 돌발, false: 단계
 *   hint0: 0, hint1: 0, hint2: 0, hint3: 0, hint4: 0, hint5: 0,
 *   hint6: 0, hint7: 0, hint8: 0, hint9: 0, hint10: 0,
 * };
 * ```
 * ## *Example*
 * ```javascript
 * const MyComponent = () => {
 *   // assume that we already know storeId and reelCount
 *   const fakeGridProps = useFakeAgGrid(storeId, reelCount);
 *   return (
 *     <AgGrid {...fakeGridProps} >
 *   );
 * };
 * ```
 */
export default function useFakeAgGrid(storeId?: string, reelCount?: ReelCountType, setting?: RemoteSetting | null): AgGridProps {
  const useAgGridParam: UseAgGridParam = React.useMemo<UseAgGridParam>(() => {
    return {
      boxProps: {
        height: '150px',
        mb: 2,
      },
      columnDefs: getFakeGridCols(),
      onCellValueChanged(e) {
        if (storeId && reelCount) {
          slotFire.setSetting(storeId, reelCount, getRemoteFakeData(e)).catch(console.error);
        }
      }
    };
  }, [storeId, reelCount]);
  const {gridRef, ...props} = useAgGrid(useAgGridParam);
  React.useEffect(() => {
    if (storeId && reelCount && setting) {
      if (hasValidFakeData(setting)) {
        gridRef.current?.setRowData(getFakeGridRowData(setting));
      }
    } else {
      gridRef.current?.setRowData([]);
    }
  }, [storeId, reelCount, setting, gridRef]);
  return props;
}

function getFakeGridCols(): ColDef[] {
  const cols: ColDef[] = [];
  cols.push({field: 'sudden', headerName: '타입', valueFormatter: getSuddenLabel, width: 80});
  for (const [hint, label] of fakeHints) {
    cols.push({field: `hint${hint}`, headerName: label, width: 100, editable: true});
  }
  return cols;
}

function hasValidFakeData(setting: RemoteSetting) {
  const {fake0 = [], fake1 = []} = setting;
  return fake0.length === fakeHints.length && fake0.length === fake1.length;
}

/**
 * 서버 데이터를 그리드 형식의 데이터로 변환하여 반환
 */
function getFakeGridRowData(setting: RemoteSetting) {
  const {fake0, fake1} = setting;
  const fake0Row: Record<string, any> = {sudden: false};
  const fake1Row: Record<string, any> = {sudden: true};
  for (const [hint] of fakeHints) {
    fake0Row[`hint${hint}`] = fake0[hint];
    fake1Row[`hint${hint}`] = fake1[hint];
  }
  return [fake0Row, fake1Row];
}

/**
 * 그리드 데이터를 서버 데이터 형태로 변환하여 반환
 */
function getRemoteFakeData(e: CellValueChangedEvent) {
  const {sudden, ...values} = e.data;
  return {[`fake${e.rowIndex}`]: Object.values(values).map(val => parseInt(val as string))};
}

const fakeHints: [SlotFakeHintType, string][] = [
  [SlotFakeHintType.upperJellyfish, '👆 해파리'],
  [SlotFakeHintType.upperBubble, '👆 버블'],
  [SlotFakeHintType.upperGaori, '👆 가오리'],
  [SlotFakeHintType.upperShark, '👆 상어'],
  [SlotFakeHintType.upperWhale, '👆 고래'],
  [SlotFakeHintType.upperGoldWhale, '👆 황금고래'],
  [SlotFakeHintType.lowerBlowfish, '👇 복어'],
  [SlotFakeHintType.lowerTurtle, '👇 거북이'],
  [SlotFakeHintType.lowerDolphin, '👇 돌고래'],
  [SlotFakeHintType.lowerShark, '👇 상어'],
  [SlotFakeHintType.lowerWhale, '👇 고래'],
];

export const initialFakeData: Record<string, FakeInfo> = {
  fake0: fakeHints.map(() => 0) as FakeInfo,
  fake1: fakeHints.map(() => 0) as FakeInfo,
};
