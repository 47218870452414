import React from 'react';
import {TextFieldProps} from '@material-ui/core';
import {AnySchema} from 'yup';

export const REQUIRED_FIELD_MESSAGE = '필수 입력 필드입니다.';

export interface UseTextFieldParams extends Omit<TextFieldProps, 'defaultValue' | 'defaultChecked' | 'value' | 'inputProps'> {
  initialValue?: string;
  readonly?: boolean;
  schema?: AnySchema;
  validateOnMount?: boolean;
  validateOnFocus?: boolean;
  validateOnBlur?: boolean;
  validateOnChange?: boolean;
}

export interface UseTextFieldValue {
  value: string | undefined;
  error: Error | undefined;
  setValue: React.Dispatch<React.SetStateAction<string | undefined>>;
  setDisabled: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  setReadonly: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  props: TextFieldProps;
}

export default function useTextField(params: UseTextFieldParams): UseTextFieldValue {
  const {initialValue, disabled: disabledParam, readonly: readonlyParam, validateOnMount, schema, required} = params;
  const [value, setValue] = React.useState<string | undefined>(initialValue);
  const [error, setError] = React.useState<Error | undefined>();
  const [disabled, setDisabled] = React.useState<boolean | undefined>(disabledParam);
  const [readonly, setReadonly] = React.useState<boolean | undefined>(readonlyParam);
  React.useEffect(() => {
    if (validateOnMount) {
      setError(validateValue(value, schema, required));
    }
  }, [required, schema, validateOnMount, value]);
  return React.useMemo<UseTextFieldValue>(() => {
    const {
      onBlur, onChange, onFocus,
      required, schema,
      validateOnBlur, validateOnChange, validateOnFocus,
      ...others
    } = params;
    const props: TextFieldProps = {
      ...defaultTextFieldProps,
      ...others,
      disabled,
      inputProps: {
        readOnly: readonly,
      },
      onChange: (e) => {
        const {target: {value: newValue}} = e;
        setValue(newValue);
        if (validateOnChange) {
          setError(validateValue(newValue, schema, required));
        }
        onChange?.(e);
      },
      onFocus: (e) => {
        if (validateOnFocus) {
          setError(validateValue(value, schema, required));
        }
        onFocus?.(e);
      },
      onBlur: (e) => {
        if (validateOnBlur) {
          setError(validateValue(value, schema, required));
        }
        onBlur?.(e);
      },
      error: Boolean(error),
      helperText: error?.message,
      value: value ?? '',
    };
    return {
      value, setValue,
      error,
      setDisabled,
      setReadonly,
      props,
    };
  }, [disabled, error, params, readonly, value]);
}

function validateValue(value?: string, schema?: AnySchema, required?: boolean): Error | undefined {
  try {
    value = !(value + '') ? undefined : value;
    schema?.[(required ?? false) ? 'required' : 'notRequired'](REQUIRED_FIELD_MESSAGE).validateSync(value);
    return undefined;
  } catch (e) {
    return e;
  }
}

const defaultTextFieldProps: TextFieldProps = {
  fullWidth: true,
  size: 'small',
};
