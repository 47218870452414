import React from 'react';
import {TextField} from '@material-ui/core';
import * as yup from 'yup';
import {ReelCountType, RemoteSetting} from '../common/slot-types';
import useIntSettingField from '../hooks/form/useIntSettingField';
import {UseTextFieldParams} from '../hooks/form/useTextField';

export interface WinPlayFieldProps {
  storeId: string | undefined;
  reelCount: ReelCountType | undefined;
  setting: RemoteSetting | null | undefined;
}

const WinPlayField: React.FC<WinPlayFieldProps> = ({storeId, reelCount, setting}) => {
  const {props} = useIntSettingField(storeId, reelCount, setting, params);
  return <TextField {...props} />;
};

const params: UseTextFieldParams = {
  name: 'winPlayCount',
  label: '윈플레이시 확률 카운트',
  schema: yup.number().typeError('숫자를 입력하세요.').integer('숫자를 입력하세요.').moreThan(0, '0보다 커야 합니다.'),
  validateOnChange: true,
  required: true,
};

export default WinPlayField;
