import React, {useCallback} from 'react';
import {useAppContext, UserOnly} from '../providers/AppProvider';
import {Box, Button, ButtonProps, Grid, makeStyles, Typography} from '@material-ui/core';
import {
  StandaloneInput,
  StandaloneInputRef,
  StandaloneSelect,
  StandaloneSelectRef
} from '../components/StandaloneInput';
import {
  baseScores,
  centerOptions, groupLabels,
  groupOptions,
  multiplyOptions,
  SlotItemGroupType,
  suddenOptions
} from '../common/slot-types';
import slotFire from '../common/slot-fire';
import {SelectOption} from '../common/types';

const Call: React.FC = () => {
  const [storeId, setStoreId] = React.useState<string>();
  const [deviceId, setDeviceId] = React.useState<string>();
  const [rewards, setRewards] = React.useState<[number, number, number]>();
  const [settingLabel, setSettingLabel] = React.useState<string>();
  const [settingValue, setSettingValue] = React.useState<any>();
  const [storeOptions, deviceOptions] = slotFire.useStoreOptions(true);
  const gridClasses = useGridStyles();
  const storeRef = React.useRef<StandaloneSelectRef>(null);
  const deviceRef = React.useRef<StandaloneSelectRef>(null);
  const groupRef = React.useRef<StandaloneSelectRef>(null);
  const centerRef = React.useRef<StandaloneSelectRef>(null);
  const multiplyRef = React.useRef<StandaloneSelectRef>(null);
  const suddenRef = React.useRef<StandaloneSelectRef>(null);
  const delayCountRef = React.useRef<StandaloneInputRef>(null);
  const continuityCountRef = React.useRef<StandaloneInputRef>(null);
  const continuityIntervalRef = React.useRef<StandaloneInputRef>(null);;
  const settingNameRef = React.useRef<StandaloneSelectRef>(null);
  const settingValueRef = React.useRef<StandaloneInputRef>(null);
  const deviceOptionsWithBlank = [{value: '', label: ''}, ...(storeId ? deviceOptions[storeId] : [])];
  const {openSharedDialog} = useAppContext();
  const remoteStore = slotFire.useStore(storeId);
  // const location = useLocation();
  React.useEffect(() => {
    if (!storeId) {
      if (storeOptions?.length === 1) {
        const newStoreId = storeOptions[0].value;
        storeRef.current?.set(newStoreId);
        deviceRef.current?.set('');
      }
    } else {
      deviceRef.current?.set('');
      groupRef.current?.set(SlotItemGroupType.fruits);
      centerRef.current?.set(0);
      multiplyRef.current?.set(10);
      suddenRef.current?.set(0);
      delayCountRef.current?.set(10);
      continuityCountRef.current?.set(0);
      continuityIntervalRef.current?.set(0);
    }
  }, [storeOptions, storeId]);
  const onScoreChange = useCallback((name: string, value: any) => {
    const group = groupRef.current?.value;
    const multiply = multiplyRef.current?.value;
    if (group >= 0 && multiply >= 1) {
      const smallWin = remoteStore?.smallWin ?? false;
      const scores: [number, number, number] = [0, 0, 0];
      scores[0] = baseScores[3][group];
      scores[1] = baseScores[4][group];
      scores[2] = baseScores[5][group];
      // 100원짜리 게임인 경우 기본 점수를 조정해줌
      if (smallWin) {
        scores[0] /= 10;
        scores[1] /= 10;
        scores[2] /= 10;
      }
      const center = centerRef.current?.value === 0 || centerRef.current?.value === '0';
      if (center) {
        scores[0] *= 2;
        scores[1] *= 2;
        scores[2] *= 2;
      }
      scores[0] *= multiply;
      scores[1] *= multiply;
      scores[2] *= multiply;
      setRewards(scores);
    }
  }, [remoteStore]);
  const onSettingNameChange = useCallback((name: string, value: string) => {
    setSettingLabel(settingNameOptions.find((i) => i.value === value)?.label ?? '설정');
  }, []);
  const onSettingValueChange = useCallback((name: string, value: string) => {
    setSettingValue(value);
  }, []);
  const onCallClick: ButtonProps['onClick'] = (e) => {
    openSharedDialog('confirm', '콜 설정', `${deviceLabel}에 콜이 설정됩니다! 계속 하시겠습니까?`, async (ok) => {
      if (ok) {
        try {
          const group = parseInt(groupRef.current?.value);
          const multiply = parseInt(multiplyRef.current?.value);
          const center = centerRef.current?.value === 0 || centerRef.current?.value === '0';
          const sudden = suddenRef.current?.value === 0 || suddenRef.current?.value === '0';
          const indexDelay = parseInt(delayCountRef.current?.value);
          const continuityCount = parseInt(continuityCountRef.current?.value);
          const continuityInterval = parseInt(continuityIntervalRef.current?.value);
          if (storeId && deviceId && group >= 0 && multiply > 0 && indexDelay > 0) {
            const groupO = groupRef.current;
            const multiplyO = multiplyRef.current;
            const centerO = centerRef.current;
            const suddenO = suddenRef.current;
            const delayCountO = delayCountRef.current;
            const continuityCount0 = continuityCountRef.current;
            const continuityInterval0 = continuityIntervalRef.current;
            await slotFire.setCall(storeId, deviceId, {group, multiply, center, sudden, indexDelay, continuityCount, continuityInterval});
            await slotFire.addLog(storeId, deviceId, `Call (${groupLabels[group]}, 배당:${multiply}, 중앙:${center?'yes':'no'}, 돌발:${sudden?'yes':'no'}, 연타:${continuityCount > 1 ? 'yes' : 'no'}/${continuityCount},${continuityInterval}) added`);
            groupO?.set(SlotItemGroupType.fruits);
            centerO?.set(0);
            multiplyO?.set(10);
            suddenO?.set(0);
            delayCountO?.set(10);
            continuityCount0?.set(0);
            continuityInterval0?.set(0);
          }
        } catch (error) {
          // TODO: 여기서 오류를 처리해야 함!
          console.log(error);
        }
      }
    });
  };
  const onSettingClick: ButtonProps['onClick'] = async (e) => {
    const settingName = settingNameRef.current?.value;
    const settingNameLabel = settingNameOptions.find(({value}) => value === settingName)?.label ?? '';
    openSharedDialog('confirm', `${settingNameLabel}`, `${deviceLabel}에 ${settingNameLabel} 됩니다! 계속 하시겠습니까?`, async (ok) => {
      if (!ok) {
        return;
      }
      try {
        const value = parseInt(settingValue);
        if (storeId && deviceId && settingName && !isNaN(value)) {
          const nameObj = settingNameRef.current;
          const valueObj = settingValueRef.current;
          await slotFire.setCall(storeId, deviceId, {[settingName]: value});
          nameObj?.set(undefined);
          valueObj?.set(undefined);
        }
      } catch (error) {
        // TODO: 여기서 오류를 처리해야 함!
        console.log(error);
      }
    });
  };
  const onInitClick: ButtonProps['onClick'] = () => {
    openSharedDialog('confirm', '기기 초기화', `${deviceLabel}의 설정값이 초기화 됩니다! 계속 하시겠습니까?`, async (ok) => {
      if (ok) {
        try {
          if (storeId && deviceId) {
            await slotFire.setCall(storeId, deviceId, {reset: true});
          }
        } catch (error) {
          // TODO: 여기서 오류를 처리해야 함!
          console.log(error);
        }
      }
    });
  };
  const onInitAllClick: ButtonProps['onClick'] = () => {
    openSharedDialog('confirm', '모든 기기 초기화', '모든 기기의 설정값이 초기화 됩니다! 계속 하시겠습니까?', async (ok) => {
      if (ok) {
        try {
          if (storeId) {
            await slotFire.setCallAll(storeId, deviceOptions[storeId].map((i: any) => i.value), {reset: true});
          }
        } catch (error) {
          // TODO: 여기서 오류를 처리해야 함!
          console.log(error);
        }
      }
    });
  };
  const deviceLabelVal = storeId ? deviceOptions[storeId].find((i: any) => i.value === deviceId)?.label : '';
  const deviceLabel = deviceId ? `기기${deviceLabelVal}` : '기기#???';
  return (
    <UserOnly fallbackPath={'/account/sign-in'} padding={2} minHeight={'calc(100vh - 48px)'}>
      <Grid classes={gridClasses} container spacing={2}>
        <Grid item xs={3}>
          <Typography variant={'h6'}>기기 선택</Typography>
          <Box mt={2}>
            <StandaloneSelect ref={storeRef} name={'storeId'} label={'매장'}  options={storeOptions} onChange={(name, value) => setStoreId(value)} />
          </Box>
          <Box mt={2}>
            <StandaloneSelect ref={deviceRef} name={'deviceId'} label={'설정할 기기'} options={deviceOptionsWithBlank} onChange={(name, value) => setDeviceId(value)} />
          </Box>
          <Box mt={2}>
            <Button variant={'contained'} size={'large'} onClick={onInitClick} disabled={!deviceId}>🚧 {deviceLabel} 초기화</Button>
          </Box>
          <Box mt={2}>
            <Button variant={'contained'} size={'large'} onClick={onInitAllClick} disabled={!storeId}>🚧 모든 기기 초기화</Button>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Typography variant={'h6'}>콜 설정</Typography>
          <Box mt={2}>
            <StandaloneSelect ref={groupRef} options={groupOptions} name={'group'} label={'이벤트 아이템 선택'} onChange={onScoreChange} />
          </Box>
          <Box mt={2}>
            <StandaloneSelect ref={centerRef} options={centerOptions} name={'center'} label={'센터 / 상하 선택'} onChange={onScoreChange} />
          </Box>
          <Box mt={2}>
            <StandaloneSelect ref={multiplyRef} options={multiplyOptions} name={'multiply'} label={'배당 선택'} onChange={onScoreChange} />
          </Box>
          <Box mt={2}>
            <StandaloneSelect ref={suddenRef} options={suddenOptions} name={'sudden'} label={'단계 / 돌발 선택'} />
          </Box>
          <Box mt={2}>
            <StandaloneInput ref={delayCountRef} name={'delayCount'} label={'몇바퀴 후 이벤트 실행'} />
          </Box>
          <Box mt={2}>
            <StandaloneInput ref={continuityCountRef} name={'continuityCount'} label={'연타 횟수 설정 (2이상 입력)'} />
          </Box>
          <Box mt={2}>
            <StandaloneInput ref={continuityIntervalRef} name={'continuityInterval'} label={'연타 간격 설정 (바퀴수)'} />
          </Box>
          <Box mt={2}>
            {rewards?.map((reward, index) => {
              return <div key={index}>{index + 3}릴 보상 : {reward >= 10000 ? `${reward/10000}만` : reward}</div>;
            })}
          </Box>
          <Box mt={2}>
            <Button variant={'contained'} size={'large'} onClick={onCallClick} disabled={!deviceId}>💵 콜({deviceLabel}) 설정</Button>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Typography variant={'h6'}>원격 설정</Typography>
          <Box mt={2}>
            <StandaloneSelect ref={settingNameRef} options={settingNameOptions} name={'settingName'} label={'설정할 내용'} onChange={onSettingNameChange} />
          </Box>
          <Box mt={2}>
            <StandaloneInput ref={settingValueRef} name={'settingValue'} label={'설정할 값'} onChange={onSettingValueChange} />
          </Box>
          <Box mt={2}>
            <Button
              variant={'contained'}
              size={'large'}
              onClick={onSettingClick}
              disabled={!(Boolean(deviceId) && Boolean(settingLabel) && Boolean(settingValue))}
            >🛠 {settingLabel} ({deviceLabel})</Button>
          </Box>
        </Grid>
      </Grid>
    </UserOnly>
  );
};

const useGridStyles = makeStyles(() => {
  return {
    root: {
      height: '100%',
    }
  };
});

const settingNameOptions: SelectOption[] = [
  {value: 'holdCount', label: '헛바퀴 설정'},
  {value: 'credit', label: '크레딧 설정'},
  {value: 'win', label: '점수(WIN) 설정'},
  {value: 'jackpot', label: '잭팟 설정'},
  {value: 'jackpotGoal', label: '잭팟목표 설정'},
];

export default Call;
