import React from 'react';
import {SelectOption} from './types';
import fire, { DocumentSnapshot, QuerySnapshot } from './fire';
import {
  EMAIL_DOMAIN,
  ReelCountType, RemoteCall,
  RemoteDevice, RemoteLog,
  RemoteSetting,
  RemoteStore,
  ROOT_COL,
  superAdmins,
} from './slot-types';
import {Moment} from 'moment';
import moment from 'moment';

class SlotFire {
  useStore(storeId?: string) {
    return fire.useDocAsShape(storeId ? `/${ROOT_COL}/${storeId}` : null, SlotFire.toStore);
  }
  useStores(): RemoteStore[] {
    const colRef = fire.colRef(`/${ROOT_COL}`).orderBy('createdAt', 'desc');
    return fire.useColAsArray(colRef, SlotFire.toStore);
  }
  useStoreOptions(includeDevices?: boolean): [SelectOption[], Record<string, SelectOption[]>] {
    const colRef = fire.colRef(`/${ROOT_COL}`).orderBy('createdAt', 'desc');
    const [snapshot, loading, error] = fire.useCol(colRef);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return React.useMemo(() => {
      // 로그인한 사용자가 접근할수 있는 스토어만 필터를 함
      const remoteStores = fire.toArray([snapshot, loading, error], SlotFire.toStore).filter((store) => {
        const storeAdminUid = `${store.devicePrefix}@${EMAIL_DOMAIN}`;
        return superAdmins.includes(fire.uid) || storeAdminUid === fire.uid;
      });
      const storeOptions: SelectOption[] = [];
      for (const store of remoteStores) {
        storeOptions.push({label: `${store.storeName} (${store.devicePrefix})`, value: store.storeId});
      }
      if (includeDevices === true) {
        const devices: Record<string, SelectOption[]> = {};
        for (const store of remoteStores) {
          devices[store.storeId] = [];
          const {devicePrefix} = store;
          for (let i = 1; i <= store.deviceCount; ++i) {
            const serial = i.toString().padStart(3, '0');
            const deviceId =`${devicePrefix}${serial}@${EMAIL_DOMAIN}`;
            const deviceLabel = `#${serial}`;
            devices[store.storeId].push({label: deviceLabel, value: deviceId});
          }
        }
        return [storeOptions, devices];
      } else {
        return [storeOptions, {}];
      }
    }, [snapshot, loading, error, includeDevices]);
  }
  useDevices(storeId?: string): RemoteDevice[] {
    const colRef = storeId ? fire.colRef(`/${ROOT_COL}/${storeId}/devices`).orderBy('deviceName') : null;
    return fire.useColAsArray(colRef, SlotFire.toDevice);
  }
  useDeviceOptions(storeId?: string): SelectOption[] {
    const colRef = storeId ? fire.colRef(`/${ROOT_COL}/${storeId}/devices`).orderBy('deviceName') : null;
    return fire.useColAsArray(colRef, SlotFire.toDeviceOption);
  }
  useSettings(storeId?: string): Record<string, RemoteSetting> | null {
    return fire.useColAsShape(storeId ? `/${ROOT_COL}/${storeId}/settings` : null, SlotFire.toSettingRecord);
  }
  useSetting(storeId?: string, reelCount?: ReelCountType): RemoteSetting | null | undefined {
    return fire.useDocAsShape(storeId && reelCount ? `/${ROOT_COL}/${storeId}/settings/${reelCount}` : null, SlotFire.toSetting);
  }
  useLogs(storeId?: string, deviceId?: string, date: Moment = moment()) {
    const path = (storeId) ? `/slot-game/${storeId}/logs` : '';
    const fromDate = fire.fromDate(date.startOf('day').toDate());
    const toDate = fire.fromDate(date.endOf('day').toDate());
    const colRef = path
      ? fire.colRef(path)
        // .where('deviceId', '==', deviceId)
        .where('createdAt', '>=', fromDate)
        .where('createdAt', '<=', toDate)
        .orderBy('createdAt', 'desc')
      : null;
    return fire.useColAsArray(colRef, SlotFire.toLog);
  }
  useProbLogs(storeId?: string) {
    return fire.useCol(storeId ? `/${ROOT_COL}/${storeId}/probs` : null);
  }
  async getSetting(storeId: string, reelCount: ReelCountType) {
    const [/*id*/, setting] = await fire.getDoc(`${ROOT_COL}/${storeId}/settings/${reelCount}`);
    return setting as RemoteSetting;
  }
  async setSetting(storeId: string, reelCount: ReelCountType, data: Partial<RemoteSetting>) {
    const docRef = fire.docRef(`${ROOT_COL}/${storeId}/settings/${reelCount}`);
    return docRef.set({...data, updatedAt: fire.now}, {merge: true});
  }
  async setCall(storeId: string, deviceId: string, data: RemoteCall) {
    return fire.set(`${ROOT_COL}/${storeId}/calls/${deviceId}`, data);
  }
  async setCallAll(storeId: string, deviceIdList: string[], data: RemoteCall) {
    const batch = fire.firestore.batch();
    for (const deviceId of deviceIdList) {
      batch.set(fire.docRef(`/${ROOT_COL}/${storeId}/calls/${deviceId}`), data);
    }
    return batch.commit();
  }
  async addLog(storeId: string, deviceId: string, log: string) {
    return fire.add(`${ROOT_COL}/${storeId}/logs`, {
      deviceId, deviceName: deviceId, log, createdAt: fire.now,
    });
  }
  // region Converters
  static toStore(docSnapshot: DocumentSnapshot): RemoteStore {
    const {storeName, devicePrefix, deviceCount, smallWin = false, createdAt} = docSnapshot.data() ?? {};
    return {
      storeId: docSnapshot.id,
      storeName, devicePrefix, deviceCount, smallWin, createdAt
    };
  }
  static toDevice(docSnapshot: DocumentSnapshot): RemoteDevice {
    const {
      deviceId,
      deviceName,
      status,
      probIndex,
      holdCount,
      reelCount,
      eventActive,
      eventInfo,
      nextEventIndex,
      nextEventInfo,
      betAmount,
      betCount,
      betTotal,
      credit,
      win,
      winTotal,
      winPlay,
      jackpot,
      jackpotDisplay,
      jackpotGoal,
      updatedAt,
    } = docSnapshot.data() ?? {};
    return {
      deviceId,
      deviceName,
      status,
      probIndex,
      holdCount,
      reelCount,
      eventActive,
      eventInfo,
      nextEventIndex,
      nextEventInfo,
      betAmount,
      betCount,
      betTotal,
      credit,
      win,
      winTotal,
      winPlay,
      jackpot,
      jackpotDisplay,
      jackpotGoal,
      updatedAt,
    };
  }
  static toDeviceOption(docSnapshot: DocumentSnapshot): SelectOption {
    const {deviceName} = docSnapshot.data() ?? {};
    return {
      value: docSnapshot.id,
      label: deviceName
    };
  }
  static toSetting(docSnapshot: DocumentSnapshot): RemoteSetting {
    // const {betAmount, maxIndex, maxHoldCount, fake0, fake1, updatedAt} = docSnapshot.data() ?? {};
    // return {betAmount, maxIndex, maxHoldCount, fake0, fake1, updatedAt};
    return docSnapshot.data() as RemoteSetting;
  }
  static toSettingRecord(query: QuerySnapshot): Record<string, RemoteSetting> {
    const res: Record<string, RemoteSetting> = {};
    query.forEach((doc) => {
      res[doc.id] = SlotFire.toSetting(doc);
    });
    return res;
  }
  static toLog(docSnapshot: DocumentSnapshot): RemoteLog {
    return docSnapshot.data() as RemoteLog;
  }
  // endregion
}

const slotFire = new SlotFire();

export default slotFire;
