import React from 'react';
import useTextField, {UseTextFieldParams, UseTextFieldValue} from './useTextField';
import {ReelCountType, RemoteSetting} from '../../common/slot-types';
import slotFire from '../../common/slot-fire';

export default function UseIntSettingField(
  storeId: string | undefined,
  reelCount: ReelCountType | undefined,
  setting: RemoteSetting | null | undefined,
  params: UseTextFieldParams
): UseTextFieldValue {
  const newParams: UseTextFieldParams = React.useMemo(() => {
    const {onBlur: onBlurParam, ...otherParams} = params;
    const onBlur: UseTextFieldParams['onBlur'] = (e) => {
      if (storeId && reelCount && params.name) {
        const {target: {value}} = e;
        const val = parseInt(value);
        if (!isNaN(val)) {
          slotFire.setSetting(storeId, reelCount, {[params.name]: val}).catch(console.log);
        }
      }
      onBlurParam?.(e);
    };
    return {onBlur, ...otherParams};
  }, [params, reelCount, storeId]);
  const res = useTextField(newParams);
  const {name} = params;
  const {setValue} = res;
  React.useEffect(() => {
    if (storeId && reelCount && setting && name) {
      const remoteValue = setting[name as keyof RemoteSetting];
      if (remoteValue) {
        setValue(remoteValue.toString());
      } else {
        setValue('');
      }
    }
  }, [name, storeId, reelCount, setting, setValue]);
  return res;
}
