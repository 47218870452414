import React from 'react';
import {CellValueChangedEvent, ColDef, ColGroupDef} from 'ag-grid-community';
import {
  baseScores,
  centerLabels,
  getSuddenLabel,
  groupLabels,
  multiples,
  ReelCountType,
  RemoteSetting
} from '../../common/slot-types';
import {AgGridProps, useAgGrid, UseAgGridParam} from './useAgGrid';
import slotFire from '../../common/slot-fire';
import {AnyRecord} from '../../common/types';

export default function useProbAgGrid(storeId?: string, reelCount?: ReelCountType, setting?: RemoteSetting | null): [AgGridProps, [number | undefined, number | undefined]] {
  const [totals, setTotals] = React.useState<[number | undefined, number | undefined]>([undefined, undefined]);
  const useAgGridParam: UseAgGridParam = React.useMemo<UseAgGridParam>(() => {
    return {
      boxProps: {
        height: '550px',
      },
      columnDefs: getProbGridCols(),
      onCellValueChanged(e) {
        if (storeId && reelCount) {
          const data = getRemoteProbData(e);
          slotFire.setSetting(storeId, reelCount, data).catch(console.error);
        }
      }
    };
  }, [storeId, reelCount]);
  const {gridRef, ...props} = useAgGrid(useAgGridParam);
  React.useEffect(() => {
    if (storeId && reelCount && setting) {
      if (hasValidProbData(setting)) {
        const [rowData, totalReward, totalAppear] = getProbGridRowData(setting);
        gridRef.current?.setRowData(rowData);
        setTotals([totalReward, totalAppear]);
      }
    } else {
      gridRef.current?.setRowData([]);
    }
  }, [storeId, reelCount, setting, gridRef]);
  return [props, totals];
}

function getProbGridCols() {
  return [
    {field: 'rowIndex', hide: true},
    {field: 'docId', hide: true},
    {
      headerName: '아이템 정보',
      children: [
        {field: 'group', headerName: '구분', valueFormatter: getItemName, width: 70, pinned: 'left'},
        {field: 'center', headerName: '위치', valueFormatter: getCenterLabel, width: 70, pinned: 'left'},
        {field: 'sudden', headerName: '타입', valueFormatter: getSuddenLabel, width: 70, pinned: 'left'},
        {field: 'score', headerName: '점수', width: 70, pinned: 'left', editable: true},
      ],
    },
    ...getMultipleColumns(),
    {field: 'subTotal', headerName: '합계', width: 100, hide: true}, // FIXME: 일단 안보이게 함
  ];
}

const getItemName: ColDef['valueFormatter'] = (params) => {
  const {group} = params.data;
  return groupLabels[group];
};

const getCenterLabel: ColDef['valueFormatter'] = (params) => {
  const {center} = params.data;
  return centerLabels[center ? 0 : 1];
};

function getMultipleColumns() {
  const columns: (ColDef | ColGroupDef)[] = [];
  for (const mul of multiples) {
    columns.push({
      headerName: `x${mul}`,
      children: [
        {field: `appear${mul}`, headerName: '출현', editable: true, width: 50},
        {field: `section${mul}`, headerName: '구간', editable: true, width: 50}
      ],
    });
  }
  return columns;
}

function hasValidProbData(setting: RemoteSetting) {
  const {prob0 = []} = setting;
  return prob0.length > 0;
}

/**
 * 서버 데이터를 그리드 형식의 데이터로 변환하여 반환
 */
function getProbGridRowData(setting: RemoteSetting): [AnyRecord[], number, number] {
  const {probCount} = setting;
  const rows: AnyRecord[] = [];
  let totalReward = 0;
  let totalAppear = 0;
  for (let i = 0; i < probCount; ++i) {
    const settingKey = `prob${i}` as keyof RemoteSetting;
    const [group, sudden, center, score, ...numbers] = setting[settingKey] as any[];
    const row: AnyRecord = {group, center, sudden, score};
    let index = 0;
    for (const mul of multiples) {
      const appear = numbers[index++];
      row[`appear${mul}`] = appear;
      row[`section${mul}`] = numbers[index++];
      totalAppear += appear;
      totalReward += score * appear * mul;
    }
    row['subTotal'] = numbers[index];
    rows.push(row);
  }
  return [rows, totalReward, totalAppear];
}

/**
 * 그리드 데이터를 서버 데이터 형태로 변환하여 반환
 */
function getRemoteProbData(e: CellValueChangedEvent) {
  const {group, sudden, center, score, subTotal, ...others} = e.data;
  const remoteRow: any[] = [group, sudden, center, parseInt(score)];
  for (const mul of multiples) {
    remoteRow.push(parseInt(others[`appear${mul}`]));
    remoteRow.push(parseInt(others[`section${mul}`]));
  }
  remoteRow.push(subTotal);
  return {[`prob${e.rowIndex}`]: remoteRow};
}

export function getInitialProbData(reelCount: ReelCountType, smallWin: boolean) {
  const groups = [0, 1, 2, 3];
  const centerList = [false, true];
  const suddenList = [false, true];
  const remoteRows: any[] = [];
  for (const center of centerList) {
    for (const sudden of suddenList) {
      for (const group of groups) {
        const score = baseScores[reelCount][group] * (center ? 2 : 1) / (smallWin ? 10 : 1);
        const numbers: number[] = [];
        multiples.forEach(() => {
          numbers.push(0);
          numbers.push(0);
        });
        remoteRows.push([group, sudden, center, score, ...numbers, 0]);
      }
    }
  }
  const probSetting: AnyRecord = {};
  for (let i = 0; i < remoteRows.length; ++i) {
    probSetting[`prob${i}`] = remoteRows[i];
  }
  probSetting['probCount'] = remoteRows.length;
  return probSetting;
}
