import firebase from 'firebase/app';
import {ColDef} from 'ag-grid-community';
import {SelectOption} from './types';

export type ReelCountType = 3 | 4 | 5 | '3' | '4' | '5';

export enum SlotItemType {
  cherry, grape, lemon, melon, orange,
  bell, bar1, bar2, bar3, seven,
  ten, jack, queen, king, ace,
  jackpot, bonus, free, scatter, wild,
}

export enum SlotItemGroupType {
  fruits, letters, bars, specials,
}

export enum ReelScoreLine {
  upper, center, lower,
}

export enum SlotHintType {
  upperJellyfish, upperBubble, upperGaori, upperShark, upperWhale, upperGoldWhale,
  lowerBlowfish, lowerTurtle, lowerDolphin, lowerShark, lowerWhale,
  dragonSuccess, dragonFail,
}

export enum SlotHintGroupType {
  upper, lower, dragon,
}

export enum SlotFakeHintType {
  upperJellyfish, upperBubble, upperGaori, upperShark, upperWhale, upperGoldWhale,
  lowerBlowfish, lowerTurtle, lowerDolphin, lowerShark, lowerWhale,
}

export interface RemoteStore {
  storeId: string;
  storeName: string;
  devicePrefix: string;
  deviceCount: number;
  smallWin?: boolean;
  createdAt: firebase.firestore.Timestamp;
}

export type FakeInfo = [number, number, number, number, number, number, number, number, number, number, number];
export type ProbInfo = [
  number, boolean, boolean, number, // group, center, sudden, score
  number, number, //   x1 appear, section
  number, number, //   x2 appear, section
  number, number, //   x3 appear, section
  number, number, //   x5 appear, section
  number, number, //   x7 appear, section
  number, number, //  x10 appear, section
  number, number, //  x20 appear, section
  number, number, //  x30 appear, section
  number, number, //  x50 appear, section
  number, number, //  x70 appear, section
  number, number, // x100 appear, section
  number, number, // x200 appear, section
  number, number, // x300 appear, section
  number, number, // x500 appear, section
  number, number, // x700 appear, section
  number, number, // x999
  number, // subTotal
];

export interface RemoteSetting {
  betAmount: number;
  maxIndex: number;
  winPlayCount: number;
  highReward: number; // 높은 점수의 보상인경우 초반에 보상이 나오는 것을 방지하기 위함 예) 10만 이상인 경우
  highPadding: number; // 높은 점수의 보상인경우 초반에 보상이 나오는 것을 방지하기 위함 예) 1000바퀴 이후 보상이 출현
  fake0: FakeInfo; // 단계
  fake1: FakeInfo; // 돌발
  prob0: ProbInfo; // 과일
  prob1: ProbInfo; // 문자
  prob2: ProbInfo; // 종바
  prob3: ProbInfo; // 특수
  prob4: ProbInfo; // 과일
  prob5: ProbInfo; // 문자
  prob6: ProbInfo; // 종바
  prob7: ProbInfo; // 특수
  prob8: ProbInfo; // 과일
  prob9: ProbInfo; // 문자
  prob10: ProbInfo; // 종바
  prob11: ProbInfo; // 특수
  prob12: ProbInfo; // 과일
  prob13: ProbInfo; // 문자
  prob14: ProbInfo; // 종바
  prob15: ProbInfo; // 특수
  probCount: number;
  updatedAt: firebase.firestore.Timestamp;
}

export interface RemoteDevice {
  deviceId: string;
  deviceName: string;
  status: string;
  probIndex: number;
  holdCount: number;
  reelCount: number;
  eventActive: boolean;
  eventInfo: number;
  nextEventIndex: number;
  nextEventInfo: number;
  betAmount: number;
  betCount: number;
  betTotal: number;
  credit: number;
  win: number;
  winTotal: number;
  winPlay: boolean;
  jackpot: number;
  jackpotDisplay: number;
  jackpotGoal: number;
  updatedAt: firebase.firestore.Timestamp;
}

export interface RemoteLog {
  log: string;
  createdAt: firebase.firestore.Timestamp;
}

export interface RemoteCall {
  group?: number;
  multiply?: number;
  center?: boolean;
  sudden?: boolean;
  indexDelay?: number;
  holdCount?: number;
  continuityCount?: number;
  continuityInterval?: number;
  credit?: number;
  win?: number;
  jackpot?: number;
  jackpotGoal?: number;
  reset?: boolean;
}

export const EMAIL_DOMAIN = 'nosong.com';

export const GAME_NAME = 'slot';

export const ROOT_COL = `${GAME_NAME}-game`;

export const superAdmins = ['yeebwn@gmail.com', 'song@nosong.com'];

export const DEF_REEL_COUNT: ReelCountType = '4';

export const DEF_MAX_INDEX: number = 100000;

export const multiples = [1, 2, 3, 5, 7, 10, 20, 30, 50, 70, 100, 200, 300, 500, 700, 999];

export const baseScores = {
  3: [1000, 2000, 3000, 5000],
  4: [2000, 3000, 5000, 10000],
  5: [3000, 5000, 10000, 20000],
};

export const groupLabels = [
  '과일 🍒', '문자 👑', '종바 🔔', '특수 🎰'
];

export const suddenLabels = [
  '돌발 ⚡', '단계'
];

export const centerLabels = [
  '센터 🎯', '상하'
];

export const probGridItemKeys = ['docId', 'rowIndex', 'group', 'center', 'sudden'];

export const betAmounts: Record<ReelCountType, number> = {
  3: 50, 4: 100, 5: 200,
};

export const winPlayCounts: Record<ReelCountType, number> = {
  3: 3, 4: 5, 5: 10,
};

export const reelCountOptions: SelectOption[] = Object.entries(betAmounts).map(([reelCount, betAmount]) => {
  return {value: reelCount, label: `${reelCount}릴 (${betAmount}원)`};
});

export const getSuddenLabel: ColDef['valueFormatter'] = (params) => {
  const {sudden} = params.data;
  return suddenLabels[sudden ? 0 : 1];
};

export const reelTypeLabel: Record<ReelCountType, string> = {
  3: '3릴 (50원)',
  4: '4릴 (100원)',
  5: '5릴 (200원)',
};

export const reelTypeOptions: SelectOption[] = Object.entries(reelTypeLabel).map(([value, label]) => ({value, label}));

export const groupOptions: SelectOption[] = groupLabels.map((label, index) => ({label, value: index}));

export const multiplyOptions: SelectOption[] = multiples.map((multiply) => ({label: `x${multiply}`, value: multiply}));

export const centerOptions: SelectOption[] = centerLabels.map((label, value) => ({label, value}));

export const suddenOptions: SelectOption[] = suddenLabels.map((label, value) => ({label, value}));
