import React from 'react';
import {Box} from '@material-ui/core';
import {AgGridReact} from 'ag-grid-react';
import useStatusGrid from '../hooks/useStatusGrid';

export interface StatusGridProps {
  storeId?: string;
}

const StatusGrid: React.FC<StatusGridProps> = (props) => {
  const {boxProps, gridProps} = useStatusGrid(props.storeId ?? '');
  return (
    <Box {...boxProps}>
      <AgGridReact {...gridProps}/>
    </Box>
  );
};

export default StatusGrid;
